import React from "react";

const ProjectDCSexOffenderSearch = () => {
    return (
        <div>
            <div>
<span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
<svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd"
        d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
        clip-rule="evenodd"/>
</svg>
</span>
            </div>
            <div className="mt-6">
                <h3 className="text-lg font-medium text-white"><a rel="noopener noreferrer"
                                                                  className="underline"
                                                                  target="_blank"
                                                                  href="https://sexoffender.dc.gov/">
                    DC Crime Cards
                    </a></h3>
                <p className="mt-2 text-base text-purple-200">
                    Led design and worked with team to develop and launch DC's Crime Cards application to enable residence to easily search near real time crime statistics. The project required collaborations with both the public and the Metropolitan Police Department to make sure we both represented the state of events in an accurate and accessible way.
                </p>
            </div>
        </div>
    );
};

export default ProjectDCSexOffenderSearch;
