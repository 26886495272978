import React from "react";

const ProjectBuyersGuide = () => {
    return (
        <div>
            <div>
<span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
  <path d="M13 7H7v6h6V7z"/>
  <path fill-rule="evenodd"
        d="M7 2a1 1 0 012 0v1h2V2a1 1 0 112 0v1h2a2 2 0 012 2v2h1a1 1 0 110 2h-1v2h1a1 1 0 110 2h-1v2a2 2 0 01-2 2h-2v1a1 1 0 11-2 0v-1H9v1a1 1 0 11-2 0v-1H5a2 2 0 01-2-2v-2H2a1 1 0 110-2h1V9H2a1 1 0 010-2h1V5a2 2 0 012-2h2V2zM5 5h10v10H5V5z"
        clip-rule="evenodd"/>
</svg>
</span>
            </div>
            <div className="mt-6">
                <h3 className="text-lg font-medium text-white"><a rel="noopener noreferrer"
                                                                  className="underline"
                                                                  target="_blank"
                                                                  href="https://wowbuyersguide.com/">Buyer's
                    Guide: Auction Bot</a></h3>
                <p className="mt-2 text-base text-purple-200">
                    After a decade plus of fascination and tracking of the economy within World
                    of Warcraft, <a href="https://www.linkedin.com/in/reed-trevelyan-42247b168/"
                                    target="_blank" rel="noopener noreferrer"
                                    className="underline"> a friend</a> and I teamed up to bring
                    my complex analysis to the
                    masses in the form of a responsive bot that empowers every player to get the
                    best price on anything available on the in-game marketplace. The bot has
                    seen over 100 installs and 1000 users engage with it as well as assisting me
                    personally in netting 2 million of in-game currency and saving millions more
                    due to optimizing decisions with data.
                </p>
            </div>
        </div>
    );
};

export default ProjectBuyersGuide;
