import React from "react";

const HobbyPhotography = () => {
    return (
        <p>
            <div className="text-2xl font-bold text-white flex justify-start">

                <div className=" my-auto pr-2">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                         fill="currentColor" className="w-6 h-6">
                        <path d="M12 9a3.75 3.75 0 100 7.5A3.75 3.75 0 0012 9z"/>
                        <path fill-rule="evenodd"
                              d="M9.344 3.071a49.52 49.52 0 015.312 0c.967.052 1.83.585 2.332 1.39l.821 1.317c.24.383.645.643 1.11.71.386.054.77.113 1.152.177 1.432.239 2.429 1.493 2.429 2.909V18a3 3 0 01-3 3h-15a3 3 0 01-3-3V9.574c0-1.416.997-2.67 2.429-2.909.382-.064.766-.123 1.151-.178a1.56 1.56 0 001.11-.71l.822-1.315a2.942 2.942 0 012.332-1.39zM6.75 12.75a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm12-1.5a.75.75 0 100-1.5.75.75 0 000 1.5z"
                              clip-rule="evenodd"/>
                    </svg>

                </div>
                <span
                    className="block text-2xl font-bold text-white"> Amateur Photographer</span>
            </div>
            <span className="mt-1 block text-base text-gray-300">I loved photography in high school on an adorable point and shoot but after years without a real camera I've fallen back in love with shooting. Feel free to check out <a
                rel="noopener noreferrer"
                className="font-medium text-white underline"
                href="http://studio.chorvinsky.com">my portfolio page</a>.</span>

        </p>
    );
};

export default HobbyPhotography;
