import React from "react";

const ProjectCovid19Checker = () => {
    return (
        <div>
            <div>
<span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
   <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
  <path fill-rule="evenodd"
        d="M7 2a1 1 0 00-.707 1.707L7 4.414v3.758a1 1 0 01-.293.707l-4 4C.817 14.769 2.156 18 4.828 18h10.343c2.673 0 4.012-3.231 2.122-5.121l-4-4A1 1 0 0113 8.172V4.414l.707-.707A1 1 0 0013 2H7zm2 6.172V4h2v4.172a3 3 0 00.879 2.12l1.027 1.028a4 4 0 00-2.171.102l-.47.156a4 4 0 01-2.53 0l-.563-.187a1.993 1.993 0 00-.114-.035l1.063-1.063A3 3 0 009 8.172z"
        clip-rule="evenodd"/>
</svg>
</span>
            </div>
            <div className="mt-6">
                <h3 className="text-lg font-medium text-white">DC
                    COVID19 Check</h3>
                <p className="mt-2 text-base text-purple-200">
                    A site quickly stood up in response to the COVID19 pandemic to support those
                    who recieved mobile exposure notifications of timely information and next
                    steps.
                    Project showcased the results of efforts to implement agile development,
                    management and
                    product practices over the prior 9 months allowing for a full release less
                    than a month after initial pandemic declaration.
                </p>
            </div>
        </div>
    );
};

export default ProjectCovid19Checker;
