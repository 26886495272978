import React from "react";

const ProjectSpeakEzRegistration = () => {
    return (
        <div>
            <div>
<span className="flex items-center justify-center h-12 w-12 rounded-md bg-white bg-opacity-10">
    <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 text-white" viewBox="0 0 20 20" fill="currentColor">
  <path
      d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z"/>
</svg>
</span>
            </div>
            <div className="mt-6">
                <h3 className="text-lg font-medium text-white">SpkEz Event Registration</h3>
                <p className="mt-2 text-base text-purple-200">
                    A bespoke tango registration system designed to allow for a simple and
                    intuitive registration process for attendees and automated leader-follower
                    balancing for organizers. Powered multiple events I ran as well as being
                    sold to two events: Folklorica in Montreal and the Nashville Tango Marathon.
                </p>
            </div>
        </div>
    );
};

export default ProjectSpeakEzRegistration;
